/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { useLocation, useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import Column from "../../Column";
import Label from "../../Label";
import Row from "../../Row";

import React from "react";

import "./OneTimeProduct.css";

// Product

const OneTimeProduct = ({ product, className, ...props }) => {
    let cn = className ? `OneTimeProduct ${className}` : "OneTimeProduct";

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const displayPrice = product.price;
    const usualPrice = product.usualPrice;
    
    const handleOnClick = (e, product) => {
        let forwardingPath = pathname && pathname.endsWith('/') ? pathname.slice(0, -1) : pathname
        forwardingPath = forwardingPath.replace("/upgrade", "")
        forwardingPath += `/join?product=${product}&duration=forever`
        navigate(forwardingPath)
    }

    return (
        <Column
            className={cn}
            justifyContent="space-between"
            style={{ border: `2px dashed ${product.color}` }}
            onClick={e => handleOnClick(e, product.value.toLowerCase())}
            {...props}
        >
            <Column className="top">
                <Label
                    className="name"
                    style={{ color: product.color }}
                >
                    {product.name}
                </Label>
                <Row alignItems="baseline">
                    <Label className="usual-price" mT={4}>
                        ${usualPrice}
                    </Label>
                    <Label className="price" mT={4}>
                        ${displayPrice}
                    </Label>
                </Row>
                <Label className="price-note">
                        {product.priceNote}
                    </Label>
                <Column mT={4}>
                    <Label className="prompt">
                        Features
                    </Label>
                    <Label className="prompt-detail" mB="4">
                        {product.promptDetail}
                    </Label>
                    {Object.keys(product.includes).map((keyName, i) => (
                        <Row alignItems="center" mB="2">
                            <FaCheckCircle className="includes-icon" />
                            <Label as="p" className="includes" mL={3}>
                                {keyName}
                            </Label>
                        </Row>
                    ))}
                </Column>
            </Column>
            <button className="start" onClick={e => handleOnClick(e, product.value.toLowerCase())}>{product.cta}</button>
        </Column>
    );
};

export default OneTimeProduct;
