/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import React from "react";
import "./AddButton.css";

// Add Button

const AddButton = ({ className }) => {
    let cn = className ? `AddButton ${className}` : "AddButton";

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate(`${pathname}/add/project`);
    };

    return (
        <button className={cn} onClick={handleOnClick}>
            <FaPlus size={20} />
        </button>
    );
};

export default AddButton;
