/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import Element from "../Element";
import React from "react";
import "./Row.css";

// Row

const Row = ({ children, className, justifyContent, alignItems, ...props }) => {
    let cn = className ? `Row ${className}` : "Row";

    // Add styles
    let styles = {};
    if (justifyContent) {
        styles["justifyContent"] = justifyContent;
    }
    if (alignItems) {
        styles["alignItems"] = alignItems;
    }

    return (
        <Element className={cn} style={styles} {...props} >
            {children}
        </Element>
    );
};

export default Row;
