/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import Element from "../Element";
import React from "react";

// Label

const Label = ({ as, children, className, bold, useFor, ...props }) => {

    let cn = className ? `Label ${className}` : "Label"
    let usAs = as ? as : "p"
    let remainingProps = {...props}
    if (bold) cn += " bold"
    if (useFor) remainingProps.for = useFor

    return (
        <Element {...props} className={cn} as={usAs}>
            {children}
        </Element>
    )
}

export default Label;