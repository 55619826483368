export const FREE_PRODUCT = {
   color: "#F5C73A",
   name: "Basic",
   month: 0,
   year: 0,
   priceIdMonth: 0,
   priceIdYear: 0,
   recommended: false,
   cta: "Start for free",
   promptDetail: "These features are always free...",
   includes: {
      "Share projects in the indie directory": true,
      "Access to the Stuf Discord": true,
      "Add 2 projects & 1 social": true
   }
}

export const INDIE_PRODUCT = {
   color: "#23d5ab",
   name: "Growth",
   month: 3,
   year: 30,
   priceIdMonth: "price_1OEPaOJe5W0d1MDijSYrUs6t",
   priceIdYear: "price_1OEPaYJe5W0d1MDiPXVFMmSO",
   recommended: true,
   cta: "Start for free",
   promptDetail: "Everything in our Basic plan plus more:",
   includes: {
      "Share projects in the indie directory": true,
      'Access to the Stuf Discord': true,
      "Unlimited projects & socials": true,
      "Visitor behavior analytics": true,
      "Brand free page": true,
   }
}

export const ONE_TIME_PAYMENT_EARLY_BIRD = {
   color: "#23d5ab",
   name: "Lifetime Pass",
   value: "lifetime_pass",
   kind: "one_time",
   price: 11,
   usualPrice: 22,
   priceId: "price_1Om89YJe5W0d1MDixHwtQ0Ol",
   priceNote: "50% off for first 100 users!",
   recommended: true,
   cta: "Get it!",
   promptDetail: "All these, plus more to come.",
   includes: {
      "Add unlimited projects & socials": true,
      'Access to the Stuf Discord': true,
      "Share projects via a clean, minimal UI": true,
   }
}