export const PROFITABLE_OPTIONS = [
    {
        type: "NON_PROFITABLE",
        icon: "☠️",
        title: "Not profitable",
    },
    {
        type: "PROFITABLE",
        icon: "🤑",
        title: "Profitable",
    }
]

export const ACTIVITY_OPTIONS = [
    {
        type: "ACTIVE",
        icon: "🟢",
        title: "Active",
    },
    {
        type: "INACTIVE",
        icon: "🔴",
        title: "Inactive",
    },
]

export const STATUS_OPTIONS = [
    {
        type: "NEW",
        icon: "🎉",
        title: "New",
    },
    {
        type: "MAIN_FOCUS",
        icon: "🔎",
        title: "Main focus",
    },
    {
        type: "FOR_SALE",
        icon: "💲",
        title: "For sale",
    },
    {
        type: "SOLD",
        icon: "💰",
        title: "Sold",
    },
    {
        type: "UP_NEXT",
        icon: "⏰",
        title: "Up next",
    },
    {
        type: "COMING_SOON",
        icon: "📆",
        title: "Coming soon",
    },
    {
        type: "IN_DEVELOPMENT",
        icon: "🛠️",
        title: "Building",
    },
    {
        type: "PLANNING",
        icon: "🧠",
        title: "Planning",
    },
    {
        type: "NOT_MAINTAINED",
        icon: "🏚️",
        title: "Not maintained",
    },
    {
        type: "DEPARTED",
        icon: "✌🏼",
        title: "Departed",
    },
];
