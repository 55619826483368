/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { useCollectionData } from "react-firebase-hooks/firestore";
import { query, collection, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../clients/firebase";

// useIsUsernameAvailable hook

const useIsUsernameAvailable = (newUsername) => {
    const [username, setUsername] = useState(newUsername);

    // Create state for our personQuery and book
    const [theQuery, setTheQuery] = useState(null);
    const [users, loading, error] = useCollectionData(theQuery);

    useEffect(() => {
        if (newUsername !== username) {
            setUsername(newUsername);
        }
    }, [newUsername, username]);

    // Construct our "user" query
    useEffect(() => {
        if (username && username.length > 0) {
            const aQuery = query(
                collection(db, "users"),
                where("username", "==", username)
            );
            setTheQuery(aQuery);
        }
    }, [username]);

    const available = users ? typeof users[0] === "undefined" : false;

    return [available, loading, error];
};

export default useIsUsernameAvailable;
