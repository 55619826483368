/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import React from "react";
import "./Element.css";

// Element

const Element = ({
    className,
    children,
    mB,
    mT,
    mL,
    mR,
    pB,
    pT,
    pL,
    pR,
    ...props
}) => {
    let cn = className ? `Element ${className}` : `Element`;
    let Klass = props.as ? props.as : "div";

   const { as, className: theClassName, ...rest } = props;

    // Add margin
    if (mB) {
        cn += ` mB-${mB}`;
    }
    if (mT) {
        cn += ` mT-${mT}`;
    }
    if (mL) {
        cn += ` mL-${mL}`;
    }
    if (mR) {
        cn += ` mR-${mR}`;
    }

    // Add padding
    if (pB) {
        cn += ` pB-${pB}`;
    }
    if (pT) {
        cn += ` pT-${pT}`;
    }
    if (pL) {
        cn += ` pL-${pL}`;
    }
    if (pR) {
        cn += ` pR-${pR}`;
    }

    return (
        <Klass {...rest} className={cn} >
            {children}
        </Klass>
    );
};

export default Element;
