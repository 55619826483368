/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { ONE_TIME_PAYMENT_EARLY_BIRD } from "../UpgradeForm/types";
import OneTimeProduct from "../UpgradeForm/OneTimeProduct";
import Element from "../Element";

import React from "react";

import "./OneTimePayment.css";

// Label

const OneTimePayment = ({ className, ...props }) => {
	let cn = className ? `OneTimePayment ${className}` : "OneTimePayment";

	return (
		<Element className={cn} {...props}>
			<Element className="products">
				<OneTimeProduct
					product={ONE_TIME_PAYMENT_EARLY_BIRD}
				/>
			</Element>
		</Element>
	);
};

export default OneTimePayment;
