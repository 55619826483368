/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import Element from "../Element";
import React from "react";

// Size class

export const BUTTON_SIZE_CLASS_BIG = "size-big"
export const BUTTON_SIZE_CLASS_MEDIUM = "size-medium"
export const BUTTON_STYLE_CLASS_LIGHT = "style-light"
export const BUTTON_STYLE_CLASS_DARK = "style-dark"
export const BUTTON_STYLE_CLASS_COLOR = "style-color"

// Button

const Button = ({ children, className, bold, useFor, ...props }) => {

    let cn = className ? `Button ${className}` : "Button"
    let remainingProps = {...props}
    if (bold) cn += " bold"
    if (useFor) remainingProps.for = useFor

    return (
        <Element {...props} className={cn} as="button">
            {children}
        </Element>
    )
}

export default Button;