/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { useNavigate } from "react-router-dom";

import {
	BUTTON_SIZE_CLASS_MEDIUM,
	BUTTON_STYLE_CLASS_COLOR,
} from "../../../components/Button";
import ActionButton from "../../../components/ActionButton";
import HeroScreenshot from "./png/hero-screenshot.png";
import Element from "../../../components/Element";
import Column from "../../../components/Column";
import Label from "../../../components/Label";
import Row from "../../../components/Row";
import React from "react";

import "./Hero.css";

// Hero

const Hero = ({ className, ...props }) => {
	let cn = className ? `Hero ${className}` : "Hero";

	const navigate = useNavigate();

	const handleOnClick = () => {
		navigate("/#hero-pricing");
	};

	const handleHeroScreenshotOnClick = () => {
		navigate("/me");
	};

	return (
		<Column {...props} className={cn} as="section" id="hero" {...props}>
			<Label as="h1" className="title">
				A link-in-bio for developers 👨🏼‍💻👩🏾‍💻 and their startups.
			</Label>
			<ActionButton
						mT={8}
						size={BUTTON_SIZE_CLASS_MEDIUM}
						style={BUTTON_STYLE_CLASS_COLOR}
						title="Claim my link"
						onClick={handleOnClick}
						className={"mobile-cta"}
					/>
			<Element className="siblings" mT={14} alignItems="flex-start" justifyContent="center">
				<picture
					className="hero-picture"
					onClick={handleHeroScreenshotOnClick}
				>
					<source
						media="(max-width: 999px)"
						srcset={HeroScreenshot}
					/>
					<source
						media="(min-width: 1000px)"
						srcset={HeroScreenshot}
					/>
					<img
						src="hero-screenshot-999.png"
						alt="Stuf Profile Screenshot"
					/>
				</picture>

				<Column className="marketing">
					<Label as="h3" className="subtitle">
						Collect and share the things you build online — add your
						projects, socials & all the bits that make you unique.
					</Label>
                    <Label as="h3" mT={8} className="subtitle">
						From your setbacks to your victories, your story is your own. Share your stuff!
					</Label>
					<ActionButton
						mT={8}
						size={BUTTON_SIZE_CLASS_MEDIUM}
						style={BUTTON_STYLE_CLASS_COLOR}
						title="Claim my link"
						onClick={handleOnClick}
					/>
					<Label as="p" mT={2} className="action-label">
						It takes less than 1 minute!
					</Label>
				</Column>
			</Element>
		</Column>
	);
};

export default Hero;
