/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import {
	BUTTON_SIZE_CLASS_MEDIUM,
	BUTTON_STYLE_CLASS_DARK,
} from "../../components/Button";
import MaxWidthContainer from "../../components/MaxWidthContainer";
import { createCustomerCheckoutSession } from "../../utils/stripe";
import ActionButton from "../../components/ActionButton";
import HappyFace from "../../assets/svg/happy_face.svg";
import Column from "../../components/Column";
import Label from "../../components/Label";
import Page from "../../components/Page";

import { onSnapshot } from "firebase/firestore";

import { useEffect, useState } from "react";

import "./PaywallPage.css";


// Paywall Page

const PaywallPage = ({ user }) => {
	// Checkout session
	const [customerCheckoutSession, setCustomerCheckoutSession] =
		useState(null);

	const [paying, setPaying] = useState(false);

	const handleOnClick = async () => {
		setPaying(true);
		// Use the user to create a checkout session
		const splitOrigin = window.location.origin.split("/");
		const preUsername = splitOrigin.slice(0, 3).join("/");
		const userUrl = `${preUsername}/${user.displayName}`;
		const session = await createCustomerCheckoutSession(
			user.uid,
			"lifetime_pass",
			"forever",
			userUrl,
			userUrl
		);
		if (session) {
			setCustomerCheckoutSession(session);
		} else {
			setPaying(false);
		}
	};

	useEffect(() => {
		if (customerCheckoutSession) {
			// Wait for the CheckoutSession to get attached by the extension
			onSnapshot(customerCheckoutSession, (snap) => {
				const { error, url } = snap.data();
				if (error) {
					// Show an error to your customer and
					// inspect your Cloud Function logs in the Firebase console.
					alert(`An error occured: ${error.message}`);
					setPaying(false);
				}
				if (url) {
					// We have a Stripe Checkout URL, let's redirect.
					setPaying(false);
					window.location.assign(url);
				}
			});
		}
	}, [customerCheckoutSession]);

	return (
		<Page className="PaywallPage">
			<MaxWidthContainer>
				<Column className="content">
					<img
						className="happy-face"
						src={HappyFace}
						alt="Happy face"
					/>
					<Label className="title">Welcome back!</Label>
					<Label className="subtitle">
						You're almost there But it seems like you still need to
						finish paying. Click the button below to proceed to the
						checkout.
					</Label>
					<ActionButton
						mT={8}
            loading={paying}
						size={BUTTON_SIZE_CLASS_MEDIUM}
						style={BUTTON_STYLE_CLASS_DARK}
						title="Go to checkout"
						className="checkout"
						onClick={handleOnClick}
					/>
				</Column>
			</MaxWidthContainer>
		</Page>
	);
};

export default PaywallPage;
