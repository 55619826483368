/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import Label from "../Label";
import React from "react";
import Row from "../Row";
import "./Selector.css";

// Selector

const Selector = ({ selected, handleOnSelect, options, className, ...props }) => {
   let cn = className ? `Selector ${className}` : "Selector";

   return (
      <Row {...props} className={cn}>
         {options && options.map(option => {
            const optionCn = option.type === selected ? "option selected" : "option"
            return (
               <Row key={`opt-${option.title}`} className={optionCn} onClick={e => handleOnSelect(option.type)}>
                  {option.icon && <Label as="p" className="icon" mR={1}>{option.icon}</Label>}
                  <Label as="p" className="state">{option.title}</Label>
               </Row>
            )
         })}
      </Row>
   )
};

export default Selector;
