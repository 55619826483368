/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { useLocation, useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import Column from "../../Column";
import Label from "../../Label";
import Row from "../../Row";

import React from "react";

import "./Product.css";

// Product

const Product = ({ billingInterval, product, className, ...props }) => {
    let cn = className ? `Product ${className}` : "Product";

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const displayPrice =
        billingInterval === "year" ? product.year : product.month;
    
    const handleOnClick = (e, product) => {
        let forwardingPath = pathname && pathname.endsWith('/') ? pathname.slice(0, -1) : pathname
        forwardingPath = forwardingPath.replace("/upgrade", "")
        forwardingPath += `/join?product=${product}&duration=${billingInterval}`
        navigate(forwardingPath)
    }

    return (
        <Column
            className={cn}
            justifyContent="space-between"
            style={
                product.name === "Growth"
                    ? { border: `2px dashed ${product.color}` }
                    : {}
            }
            {...props}
        >
            <Column className="top">
                <Label
                    className="name"
                    style={
                        product.name === "Growth"
                            ? { color: product.color }
                            : {}
                    }
                >
                    {product.name}
                </Label>
                <Row alignItems="baseline">
                    <Label className="price" mT={4}>
                        ${displayPrice}
                    </Label>
                    <Label
                        className="price-detail"
                        mL={displayPrice === 0 ? 1 : 0}
                    >
                        {displayPrice === 0 ? "always" : `/ ${billingInterval}`}
                    </Label>
                </Row>
                <Column mT={4}>
                    <Label className="prompt">
                        Features
                    </Label>
                    <Label className="prompt-detail" mB="4">
                        {product.promptDetail}
                    </Label>
                    {Object.keys(product.includes).map((keyName, i) => (
                        <Row alignItems="center" mB="2">
                            <FaCheckCircle className="includes-icon" />
                            <Label as="p" className="includes" mL={3}>
                                {keyName}
                            </Label>
                        </Row>
                    ))}
                </Column>
            </Column>

            <button className="start" onClick={e => handleOnClick(e, product.name.toLowerCase())}>{product.cta}</button>
        </Column>
    );
};

export default Product;
