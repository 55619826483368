/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { getProjectSlug, updateUserProject } from "../../utils/project";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useEffect, useMemo, useState } from "react";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { IoMdCloudUpload } from "react-icons/io";
import { storage } from "../../clients/firebase";
import Element from "../Element";
import Row from "../Row";

import "./UploadLogoForm.css";

// Upload Logo Form

const UploadLogoForm = ({ user, project, onLoading }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const projectSlug = useMemo(() => {
		let slug = "";
		if (project) {
			slug = getProjectSlug(project?.title);
		}
		return slug;
	}, [project]);

	const fileRef = useMemo(() => {
		const fileRef = project
			? ref(storage, `projects/logos/${projectSlug}` + ".png")
			: null;
		return fileRef;
	}, [project]);

	useEffect(() => {
		onLoading(loading);
	}, [loading]);

	const uploadFile = async (file) => {
		try {
			setLoading(true);
			await uploadBytes(fileRef, file);
			const photoURL = await getDownloadURL(fileRef);
			await updateUserProject(user?.uid, projectSlug, { photoURL });
			setLoading(false);
			setError(null);
		} catch (err) {
			setLoading(false);
			setError(err);
		}
	};

	return (
		<Element className="UploadLogoForm">
			<input
				id="file-upload"
				type="file"
				onChange={(e) => {
					uploadFile(e.target.files[0]);
				}}
			/>
			<Row>
				<label for="file-upload" className="fileUpload" style={{ marginRight: "var(--spacing-2x)"}}>
					Upload logo
					<IoMdCloudUpload size={20} />
				</label>
				{project?.photoURL && (
					<label className="fileRemove">
						Remove
						<RiDeleteBin2Fill size={20} />
					</label>
				)}
			</Row>
		</Element>
	);
};

export default UploadLogoForm;
