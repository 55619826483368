/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { doc, serverTimestamp, setDoc, deleteDoc } from "firebase/firestore";
import { db } from "../clients/firebase";

// Update User Project

export const updateUserProject = async (userUid, projectSlug, updates) => {
	const projectRef = doc(db, "projects", `${userUid}-${projectSlug}`);
	return await setDoc(
		projectRef,
		{ ...updates, updated_at: serverTimestamp() },
		{ merge: true }
	);
};

// Delete User Project

export const deleteUserProject = async (userUid, projectSlug) => {
	const projectRef = doc(db, "projects", `${userUid}-${projectSlug}`);
	return await deleteDoc(projectRef);
};

// Slug

export const getProjectSlug = (title) => {
	let slug = "";
	if (title) {
		slug = title
			.toLowerCase()
			.replace(/ /g, "-")
			.replace(/[^\w-]+/g, "");
	}
	return slug;
};
