/*
 * Copyright (C) 2022 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { Icon } from "@blueprintjs/core"
import GoogleG from './google_g.svg';
import React from "react"

// Google G Icon

const GoogleGIcon = ({ height, width }) => {
    return <Icon icon={<img src={GoogleG} alt="Google G Logo" height={height} width={width} />} />
}

GoogleGIcon.defaultProps = {
    height: '14px',
    width: '14px'
}

export default GoogleGIcon;