/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { useDocument } from "react-firebase-hooks/firestore";
import { query, doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../clients/firebase";

// useProject hook

const useProject = (docId) => {
	// Construct our projects query
	const [projectQuery, setProjectQuery] = useState(null);
	const [project, setProject] = useState(null);
	const [projectSnapshot, projectLoading, projectError] =
		useDocument(projectQuery);

	useEffect(() => {
		if (projectSnapshot) {
			setProject(projectSnapshot.data());
		}
	}, [projectSnapshot]);

	// Create the query to get the user's projects
	useEffect(() => {
		if (docId && (!project || project && projectSnapshot.id !== docId)) {
			const aQuery = query(
				doc(db, "projects", docId)
			);
			setProjectQuery(aQuery);
		}
	}, [docId, project, projectSnapshot]);

	return [project, projectLoading, projectError];
};

export default useProject;
