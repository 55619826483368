/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { TbMoodCry } from "react-icons/tb";
import "./NoProjectsPlaceholder.css";
import Column from "../Column";
import Label from "../Label";
import React from "react";

// No Projects Placeholder

const NoProjectsPlaceholder = ({ className, ...props }) => {
    let cn = className ? `NoProjectsPlaceholder ${className}` : "NoProjectsPlaceholder";

    return (
       <Column {...props} className={cn} alignItems="center">
          <TbMoodCry size={60} color={"white"} />
          <Label className="empty" as="h2" mT={2} bold>No projects yet</Label>
        </Column>
    );
};

export default NoProjectsPlaceholder;
