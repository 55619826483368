/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { TbMoodHappy } from "react-icons/tb";
import { FaArrowDown } from "react-icons/fa";
import "./JoinNowPlaceholder.css";
import Column from "../Column";
import Label from "../Label";
import React from "react";

// Join Now Placeholder

const JoinNowPlaceholder = ({ className, ...props }) => {
    let cn = className ? `JoinNowPlaceholder ${className}` : "JoinNowPlaceholder";

    return (
       <Column {...props} className={cn} alignItems="center">
          <TbMoodHappy size={60} />
          <Label className="empty" as="h2" mT={2} mB={3} bold>Join for free today!</Label>
          <FaArrowDown size={26} />
        </Column>
    );
};

export default JoinNowPlaceholder;
