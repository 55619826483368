/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { updateUserProfile } from "../../utils/userProfile";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { IoMdCloudUpload } from "react-icons/io";
import { storage } from "../../clients/firebase";
import { updateProfile } from "firebase/auth";
import { useMemo, useState } from "react";
import Element from "../Element";
import Row from "../Row";

import "./UploadAvatarForm.css";

// Upload Avtar Form

const UploadAvatarForm = ({ user }) => {
	const [response, setResponse] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const fileRef = useMemo(() => {
		const fileRef = user
			? ref(storage, `users/avatars/${user?.uid}` + ".png")
			: null;
		return fileRef;
	}, [user]);

	const uploadFile = async (file) => {
		try {
			const uploadRes = await uploadBytes(fileRef, file);
			// Set some state base on response
			setResponse(uploadRes);
			setLoading(false);
			setError(null);
			// Update the user's profile
			const photoURL = await getDownloadURL(fileRef);
			updateProfile(user, { photoURL });
			updateUserProfile(user.uid, { photoURL });
		} catch (err) {
			setResponse(null);
			setLoading(false);
			setError(err);
		}
	};

	return (
		<Element className="UploadAvatarForm">
			<input
				id="file-upload"
				type="file"
				onChange={(e) => {
					uploadFile(e.target.files[0]);
				}}
			/>
			<Row>
				<label
					for="file-upload"
					className="fileUpload"
					style={{ marginRight: "var(--spacing-2x)" }}
				>
					Upload photo
					<IoMdCloudUpload size={20} />
				</label>
				{user?.photoURL && (
					<label className="fileRemove">
						Remove
						<RiDeleteBin2Fill size={20} />
					</label>
				)}
			</Row>
		</Element>
	);
};

export default UploadAvatarForm;
