/*
 * Copyright (C) 2022 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

// Alert handlers

export const toastIt = (msg, kind = "info") => {
    console.log(msg);
    switch (kind) {
        case "info": {
            toast.info(msg);
            break;
        }
        case "success": {
            toast.success(msg);
            break;
        }
        case "warn": {
            toast.warn(msg);
            break;
        }
        default:
            toast.info(msg);
            break;
    }
};

// Errors

export const USER_DOES_NOT_EXIST = "Local: Error (auth/user-not-found).";
export const FIREBASE_USER_NOT_FOUND = "Firebase: Error (auth/user-not-found).";
export const FIREBASE_INVALID_EMAIL = "Firebase: Error (auth/invalid-email).";
export const FIREBASE_EMAIL_ALREADY_IN_USE =
    "Firebase: Error (auth/email-already-in-use).";
export const ADD_PRODUCT_URL_FAIL = "add_product_url_fail";
