/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import {
	app,
	logout,
	httpsCallable,
	getFunctions,
} from "../../clients/firebase";
import { useNavigate, useLocation } from "react-router-dom";
import { updateUserProfile } from "../../utils/userProfile";
import UploadAvatarForm from "../UploadAvatarForm";
import React, { useState, useMemo } from "react";
import { IoClose } from "react-icons/io5";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import Loading from "../Loading";
import Avatar from "../Avatar";
import Column from "../Column";
import Label from "../Label";
import Row from "../Row";

import "./EditUserForm.css";

// Edit User Form

const EditUserForm = ({ user, userProfile, className, ...props }) => {
	let cn = className ? `EditUserForm ${className}` : "EditUserForm";

	const { pathname } = useLocation();
	const navigate = useNavigate();
	const functions = getFunctions(app);

	// Loading & redirecting
	const [loading, setLoading] = useState(false);
	const [redirecting, setRedirecting] = useState(false);

	// New data
	const [bio, setBio] = useState(userProfile?.bio);
	const [fullName, setFullName] = useState(userProfile?.name);
	const [twitter, setTwitter] = useState(userProfile?.twitter);
	const [youTube, setYouTube] = useState(userProfile?.youtube);
	const [gitHub, setGitHub] = useState(userProfile?.github);
	const [instagram, setInstagram] = useState(userProfile?.instagram);
	const [linkedin, setLinkedin] = useState(userProfile?.linkedin);
	const [website, setWebsite] = useState(userProfile?.website);

	// Original data
	const [originalBio] = useState(userProfile?.bio);
	const [originalFullName] = useState(userProfile?.name);
	const [originalTwitter] = useState(userProfile?.twitter);
	const [originalYouTube] = useState(userProfile?.youtube);
	const [originalGitHub] = useState(userProfile?.github);
	const [originalInstagram] = useState(userProfile?.instagram);
	const [originalLinkedin] = useState(userProfile?.linkedin);
	const [originalWebsite] = useState(userProfile?.website);

	const isChanged = useMemo(() => {
		const isChanged =
			originalBio !== bio ||
			originalFullName !== fullName ||
			originalTwitter !== twitter ||
			originalYouTube !== youTube ||
			originalGitHub !== gitHub ||
			originalInstagram !== instagram ||
			originalLinkedin !== linkedin ||
			originalWebsite !== website;
		return isChanged;
	}, [
		originalBio,
		originalFullName,
		originalTwitter,
		originalYouTube,
		originalGitHub,
		originalInstagram,
		bio,
		fullName,
		twitter,
		youTube,
		gitHub,
		instagram,
		linkedin,
		website
	]);

	const updates = useMemo(() => {
		let updates = {};
		if (bio || bio === "") updates.bio = bio;
		if (fullName || fullName === "") updates.name = fullName;
		if (twitter || twitter === "") updates.twitter = twitter;
		if (youTube || youTube === "") updates.youtube = youTube;
		if (gitHub || gitHub === "") updates.github = gitHub;
		if (instagram || instagram === "") updates.instagram = instagram;
		if (linkedin || linkedin === "") updates.linkedin = linkedin;
		if (website || website === "") updates.website = website;
		return updates;
	}, [bio, fullName, twitter, youTube, gitHub, instagram, linkedin, website]);

	// Handlers
	const handleOnClose = () => {
		document.body.style.overflow = "auto";
		navigate(pathname.replace("/edit/user", ""));
	};

	const handleOnSave = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setLoading(true);
		updateUserProfile(user.uid, updates);
		setTimeout(() => {
			setLoading(false);
			handleOnClose();
		}, 1000);
	};

	const handleOnCreateCustomerPortal = async () => {
		setLoading(true);
		setRedirecting(true);
		const createPortalLink = httpsCallable(
			functions,
			"ext-firestore-stripe-payments-createPortalLink"
		);
		createPortalLink({ returnUrl: window.location.href })
			.then((result) => {
				const data = result.data;
				window.location.assign(data.url);
				setLoading(false);
				setRedirecting(false);
			})
			.catch((err) => {
				console.log(
					"Error: Failed to create Stripe customer portal: ",
					err
				);
				setLoading(false);
				setRedirecting(false);
			});
	};

	const handleOnLogout = () => {
		logout();
		navigate(pathname.replace("/edit/user", ""));
	};

	const preventHandler = (e) => {
		e.preventDefault();
	};

	return (
		<Popup
			open={true}
			className="EditUserFormPopup"
			onClose={handleOnClose}
			closeOnDocumentClick
			closeOnEscape
		>
			<button className="close" onClick={handleOnClose}>
				<IoClose size={26} />
			</button>
			<form className={cn} {...props} onSubmit={preventHandler}>
				<Column className="container">
					<Avatar size={70} src={user?.photoURL} />
					<UploadAvatarForm user={user} />
					<Label as="h3" className="subtitle">
						About
					</Label>
					<input
						type="text"
						className="fullname"
						value={fullName}
						maxLength="50"
						onChange={(e) => setFullName(e.target.value)}
						placeholder="Your full name"
					/>
					<textarea
						type="text"
						className="bio"
						value={bio}
						maxLength="280"
						onChange={(e) => setBio(e.target.value)}
						placeholder="Add a short bio here"
					/>
					<Label as="h3" className="subtitle">
						Website
					</Label>
					<input
						type="text"
						className="website"
						value={website}
						maxLength="50"
						onChange={(e) => setWebsite(e.target.value)}
						placeholder="Website link"
					/>
					<Label as="h3" className="subtitle">
						Socials
					</Label>
					<input
						type="text"
						className="social"
						value={twitter}
						maxLength="50"
						onChange={(e) => setTwitter(e.target.value)}
						placeholder="Twitter link"
					/>
					<input
						type="text"
						className="social"
						value={youTube}
						maxLength="50"
						onChange={(e) => setYouTube(e.target.value)}
						placeholder="YouTube link"
					/>
					<input
						type="text"
						className="social"
						value={gitHub}
						maxLength="50"
						onChange={(e) => setGitHub(e.target.value)}
						placeholder="GitHub link"
					/>
					<input
						type="text"
						className="social"
						value={linkedin}
						maxLength="50"
						onChange={(e) => setLinkedin(e.target.value)}
						placeholder="LinkedIn link"
					/>
					{/* <input
						type="text"
						className="social"
						value={instagram}
						maxLength="50"
						onChange={(e) => setInstagram(e.target.value)}
						placeholder="Instagram link"
					/> */}
					<Label as="h3" className="subtitle">
						Account
					</Label>
					<Row className="account-container">
						<button
							className="customerPortal"
							onClick={handleOnCreateCustomerPortal}
							disabled={loading}
						>
							{redirecting && (
								<Label>Redirecting to Stripe</Label>
							)}
							{!redirecting && <Label>Manage subscription</Label>}
						</button>
						<button
							className="logout"
							onClick={handleOnLogout}
							disabled={loading}
						>
							<Label>Log out</Label>
						</button>
					</Row>
				</Column>
			</form>
			<Row className="buttons">
				<button
					className="save"
					onClick={handleOnSave}
					disabled={!isChanged}
				>
					{!loading && <Label>Save</Label>}
					{loading && <Loading color="#FFF" />}
				</button>
			</Row>
		</Popup>
	);
};

export default EditUserForm;
