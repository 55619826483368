/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { NavLink, useLocation } from "react-router-dom";
import React, { useMemo } from "react";

import Row from "../Row";

import "./AuthFooter.css";

// AuthFooter
const AuthFooter = ({ className, user, userProfile }) => {
	let cn = className ? `AuthFooter ${className}` : "AuthFooter";

	const { pathname } = useLocation();

	const basePath = useMemo(() => {
		return pathname.endsWith("/") ? pathname.slice(0, -1) : pathname;
	}, [pathname]);

	// Is the person who's logged in viewing themself?
	const isSelf = useMemo(() => {
		const isSelf = userProfile && pathname.includes(user?.displayName);
		return isSelf;
	}, [userProfile, pathname, user?.displayName]);

	return (
		<footer className={cn}>
			<Row>

				<NavLink to="/" className="link">
					Built with <span className="colored-text">Stuf.dev</span>
				</NavLink>
				{user && !isSelf && (
					<NavLink to={`/${userProfile?.username}`} className="link login">
						My page
					</NavLink>
				)}
				{!user && (
					<NavLink to={`${basePath}/login`} className="link login">
						Login
					</NavLink>
				)}
			</Row>
			{/* <Label className="copyright">©2024 stuf.dev</Label> */}
		</footer>
	);
};

export default AuthFooter;
