/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import {
    BUTTON_SIZE_CLASS_MEDIUM,
    BUTTON_STYLE_CLASS_COLOR,
} from "../../../components/Button";
import ActionButton from "../../../components/ActionButton";
import { useLocation, useNavigate } from "react-router-dom";
import Column from "../../../components/Column";
import Label from "../../../components/Label";
import React from "react";
import "./LastCTA.css";

// LastCTA

const LastCTA = ({ className, ...props }) => {
    let cn = className ? `LastCTA ${className}` : "LastCTA";

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate("/#one-time-payment");
    };

    return (
        <Column {...props} className={cn}>
            <Label as="h1" className="title" mT={5}>
                Share your startups and get more customers!
            </Label>
            <ActionButton
                mT={8}
                size={BUTTON_SIZE_CLASS_MEDIUM}
                style={BUTTON_STYLE_CLASS_COLOR}
                title="Claim my link"
                onClick={handleOnClick}
            />
       </Column>
    );
};

export default LastCTA;

