/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { BrowserRouter, Routes, Route } from "react-router-dom";
import getCustomClaimRole from "./utils/getCustomerClaimRole";
import EditProjectForm from "./components/EditProjectForm";
import { useAuthState } from "react-firebase-hooks/auth";
import EditUserForm from "./components/EditUserForm";
import useUserProfile from "./hooks/useUserProfile";
import UpgradeForm from "./components/UpgradeForm";
import SignUpForm from "./components/SignUpForm";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainLinks from "./components/MainLinks";
import LoginForm from "./components/LoginForm";
import NotFoundPage from "./modules/NotFound";
import { useEffect, useState } from "react";
import { auth } from "./clients/firebase";
import PersonPage from "./modules/Person";
import Layout from "./components/Layout";
import { createPortal } from "react-dom";
import HomePage from "./modules/Home";

import "./App.css";
import useDidUserPay from "./hooks/useDidUserPay";
import PaywallPage from "./modules/Paywall";

// App

function App() {
	// Get the user & profile, and the user's projects
	const [user] = useAuthState(auth);
	const [userProfile] = useUserProfile(user);

	// The user's claim role
	const [claimRole, setClaimRole] = useState(null);
	const [didFetchClaim, setDidFetchClaim] = useState(false);

	useEffect(() => {
		const fetchClaimRole = async () => {
			const fetchedClaimRole = await getCustomClaimRole();
			setClaimRole(fetchedClaimRole);
		};
		if (user && !claimRole) {
			setDidFetchClaim(true);
			fetchClaimRole();
		}
	}, [user, didFetchClaim, setDidFetchClaim]);

	const [loggedInUserIsPaid, loading] = useDidUserPay(user?.uid)

	// Toast
	const toastRoot = document.getElementById("toast-root");
	const toastContainer = createPortal(
		<ToastContainer position="bottom-right" closeOnClick />,
		toastRoot
	);

	if (user && !loggedInUserIsPaid && !loading) {
		return <PaywallPage user={user} />
	}
	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route index element={<HomePage user={user} />} />
						<Route path="/join" element={<SignUpForm />} />
						<Route path="/login" element={<LoginForm />} />
						{/* <Route path="/upgrade" element={<HomePage />} /> */}
						<Route
							path=":username/*"
							element={
								<PersonPage
									user={user}
									userProfile={userProfile}
								/>
							}
						/>
						<Route path="*" element={<NotFoundPage />} />
					</Route>
				</Routes>
				<Routes>
					<Route
						path=":username?/upgrade"
						element={<UpgradeForm user={user} />}
					/>
					<Route path=":username/login" element={<LoginForm />} />
					<Route path=":username/join" element={<SignUpForm />} />
					<Route
						path=":username/:cmd/project"
						element={
							<EditProjectForm
								user={user}
								userProfile={userProfile}
							/>
						}
					/>
					<Route
						path=":username/edit/user"
						element={
							<EditUserForm
								user={user}
								userProfile={userProfile}
							/>
						}
					/>
					<Route path="*" element={<NotFoundPage />} />
				</Routes>
				<Routes>
					<Route path="*upgrade" element={<UpgradeForm />} />
				</Routes>
				<MainLinks user={user} userProfile={userProfile} />
				{toastContainer}
			</BrowserRouter>
		</div>
	);
}

export default App;
