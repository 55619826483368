/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { FaInstagramSquare, FaTwitterSquare } from "react-icons/fa";
import MaxWidthContainer from "../MaxWidthContainer";
import Element from "../Element";
import Column from "../Column";
import Label from "../Label";
import React from "react";
import Row from "../Row";
import "./Footer.css";

// Footer

const Footer = () => {
	return (
		<Element as="footer" className="Footer">
			<MaxWidthContainer className="container">
				<Column className="inner-container" width="100%">
					<Row className="subcontainer" width="100%">
						<Label as="h4" className="email">
							hi@stuf.dev
						</Label>
					</Row>
					<Label className="copyright">
						©2023 stuf.dev. All rights reserved.
					</Label>
				</Column>
				<Row as="h4" className="links">
                    <a
                        href="https://www.instagram.com/stuffdotdev"
                        target="_blank"
                        rel="noreferrer"
                        className="instagram"
                    >
                        <FaInstagramSquare size={32} />
                    </a>
                    <a
                        href="https://www.twitter.com/stuffdotdev"
                        target="_blank"
                        rel="noreferrer"
                        className="twitter"
                    >
                        <FaTwitterSquare size={32} />
                    </a>
				</Row>
			</MaxWidthContainer>
		</Element>
		// <div className="Footer">
		//     <MaxWidthContainer className="container">
		//         <Column className="inner-container" width="100%">
		//             <Row className="subcontainer" width="100%">
		//                 <Row className="left">
		//                     <Column className="contact">
		//                         <Label as="h3" className="subheader" mB={6}>
		//                             Contact
		//                         </Label>
		//                         <Label as="h4" className="email">
		//                             hi@stuf.dev
		//                         </Label>
		//                     </Column>
		//                 </Row>
		//                 <Row className="right">
		//                     <Column className="follow">
		//                         <Label as="h3" className="subheader" mB={6}>
		//                             Socials
		//                         </Label>
		//                         
		//                         <a
		//                             href="https://www.twitter.com/stuffdotdev"
		//                             target="_blank"
		//                             rel="noreferrer"
		//                         >
		//                             Twitter
		//                         </a>
		//                     </Column>
		//                     <Column className="nav">
		//                         <Label as="h3" className="subheader" mB={6}>
		//                             Menu
		//                         </Label>
		//                         <NavLink className="link" to="/#hero">
		//                             Home
		//                         </NavLink>
		//                         <NavLink className="link" to="/#hero-faqs">
		//                             FAQs
		//                         </NavLink>
		//                         <NavLink className="link" to="#hero-pricing">
		//                             Pricing
		//                         </NavLink>
		//                         <NavLink className="link" to="/about">
		//                             About
		//                         </NavLink>
		//                     </Column>
		//                 </Row>
		//             </Row>
		//             <h6 className="copyright">© 2023 Stuf. All rights reserved.</h6>
		//         </Column>
		//     </MaxWidthContainer>
		// </div>
	);
};

export default Footer;