/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import Loading from "../Loading";
import Button from "../Button";
import Label from "../Label";

import React from "react";

import "./ActionButton.css";

// Action Button

const ActionButton = ({ title, className, style, size, loading, onClick, ...props }) => {
    let cn = className ? `ActionButton ${className}` : `ActionButton`;

    if (style) cn += ` ${style}`
    if (size) cn += ` ${size}`

    const handleOnClick = () => {
        if (typeof onClick === "function") {
            onClick()
        }
    };

    return (
        <Button disabled={loading} onClick={handleOnClick} {...props} className={cn}>
            {!loading && <Label>{title}</Label>}
            {loading && <Loading color={"var(--white-color)"} />}
        </Button>
    );
};

export default ActionButton;

