/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { AVATAR_COLORS } from "../../utils/colors";
import Loading from "../Loading";
import Element from "../Element";
import React from "react";

import "./Avatar.css";

// Avatar

const Avatar = ({
    className,
    size,
    src,
    forceShowLoading,
    ...props
}) => {

    let cn = className ? `Avatar ${className}` : "Avatar";
    const rndmHex = AVATAR_COLORS[Math.floor(Math.random() * AVATAR_COLORS.length)]

    return (
        <Element {...props} className={cn} style={{ height: size, minHeight: size, width: size, minWidth: size }}>
            {src && !forceShowLoading && <img src={`${src}&${(new Date().getTime())}`} alt="User avatar" />}
            {!src && !forceShowLoading && <div className="placeholder" style={{ backgroundColor: rndmHex }} alt="User avatar" />}
            {forceShowLoading && <Loading size={size} color={"white"} />}
        </Element>
    );
};

export default Avatar;
