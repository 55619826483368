/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import HighlightedLabel from "../../../components/HighlightedLabel";
import Column from "../../../components/Column";
import Label from "../../../components/Label";
import OneTimePayment from "../../../components/OneTimePayment";

import { useLocation, useNavigate } from "react-router-dom";
import React from "react";

import "./Pricing.css";

// Pricing

const Pricing = ({ className, ...props }) => {
    let cn = className ? `Pricing ${className}` : "Pricing";

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate(`${pathname}/upgrade`);
    };

    return (
        <Column {...props} className={cn} as="section" id="hero-pricing">
            <Label as="h1" className="title" id="one-time-payment" mT={12}>
                One-time payment
            </Label>
            <OneTimePayment />
       </Column>
    );
};

export default Pricing;

