/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import {
	FaTwitterSquare,
	FaInstagramSquare,
	FaGithubSquare,
	FaYoutubeSquare,
	FaLinkedin,
} from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import Element from "../../../components/Element";
import Avatar from "../../../components/Avatar";
import Column from "../../../components/Column";
import Label from "../../../components/Label";
import { IoMdSettings } from "react-icons/io";
import { MdVerified } from "react-icons/md";
import { FaRocket } from "react-icons/fa";
import Row from "../../../components/Row";
import { useMemo } from "react";
import "./Header.css";

// Header

const Header = ({ canEdit, user, userProfile, userSubscription }) => {
	const { pathname } = useLocation();

	// Get the name and bio
	const { userName, bio } = useMemo(() => {
		const userName = userProfile?.name ? userProfile.name : "";
		const bio = userProfile?.bio
			? userProfile.bio
			: userName !== "404 Not Found"
			? `This person has not yet added a bio.`
			: "You're in luck. No one has claimed this page yet, so you still can!";
		return { userName, bio };
	}, [userProfile]);

	// Get socials
	const {
		userYoutube,
		userTwitter,
		userInstagram,
		userGithub,
		userLinkedin,
		userWebsite,
	} = useMemo(() => {
		const userYoutube =
			userProfile?.youtube && userProfile.youtube !== ""
				? userProfile.youtube
				: null;
		const userTwitter =
			userProfile?.twitter && userProfile.twitter !== ""
				? userProfile.twitter
				: null;
		const userInstagram =
			userProfile?.instagram && userProfile.instagram !== ""
				? userProfile.instagram
				: null;
		const userGithub =
			userProfile?.github && userProfile.github !== ""
				? userProfile.github
				: null;
		const userWebsite =
			userProfile?.website && userProfile.website !== ""
				? userProfile.website
				: null;
		const userLinkedin =
			userProfile?.linkedin && userProfile.linkedin !== ""
				? userProfile.linkedin
				: null;
		return {
			userYoutube,
			userTwitter,
			userInstagram,
			userGithub,
			userLinkedin,
			userWebsite,
		};
	}, [userProfile]);

	return (
		<Column className="Header">
			<Avatar src={userProfile?.photoURL} />
			{userSubscription && (
				<Element className="subscription-container">
					<MdVerified size={22} />
				</Element>
			)}
			<Label as="h1" className="name" mT={3} bold>
				{userName}
			</Label>
			<Label as="h2" className="bio">
				{bio}
			</Label>
			{userWebsite && (
				<NavLink target="_blank" to={userWebsite} className="website">
					{userWebsite.replace("http://", "").replace("https://", "")}
				</NavLink>
			)}
			<Row className="socials-container">
				{userTwitter && (
					<NavLink
						target="_blank"
						className="twitter"
						to={userTwitter}
					>
						<FaTwitterSquare size={32} />
					</NavLink>
				)}
				{userGithub && (
					<NavLink target="_blank" className="github" to={userGithub} >
						<FaGithubSquare size={32} />
					</NavLink>
				)}
				{userLinkedin && (
					<NavLink className="linkedin" to={userLinkedin} target="_blank">
						<FaLinkedin size={32} />
					</NavLink>
				)}

				{userYoutube && (
					<NavLink className="youtube" to={userYoutube} target="_blank">
						<FaYoutubeSquare size={32} />
					</NavLink>
				)}
				{/* {userInstagram && (
					<>
						<NavLink
							target="_blank"
							className="instagram"
							to={userInstagram}
						>
							<FaInstagramSquare size={32} />
						</NavLink>
					</>
				)} */}
			</Row>
			<Row>
				{user && canEdit && (
					<NavLink className="edit" to={`${pathname}/edit/user`}>
						Account
						<IoMdSettings />
					</NavLink>
				)}
				{/* {user && canEdit && !userSubscription && (
					<NavLink className="upgrade" to={`${pathname}/pricing`}>
						Upgrade
						<FaRocket />
					</NavLink>
				)} */}
			</Row>
		</Column>
	);
};

export default Header;
