/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import NoProjectsPlaceholder from "../NoProjectsPlaceholder";
import JoinNowPlaceholder from "../JoinNowPlaceholder";
import ProjectGridItem from "../ProjectGridItem";
import React, { useMemo } from "react";
import Element from "../Element";
import Label from "../Label";
import "./ProjectGrid.css";
import Row from "../Row";

// Project Grid

const ProjectGrid = ({
	className,
	projects,
	title,
	canEdit,
	fourZeroFour,
	...props
}) => {
	let cn = className ? `ProjectGrid ${className}` : "ProjectGrid";

	const nonZeroProjects = useMemo(() => {
		const nonZeroProjects =  projects && projects.length > 0;
		return nonZeroProjects;
	}, [projects]);

	const sortedProjects = useMemo(() => {
		let sortedProjects = projects;
		if (nonZeroProjects) {
			sortedProjects.sort((a, b) => {
				let result = 0;
                const aDate = a.updated_at ? new Date(a.updated_at.seconds * 1000) : 0
                const bDate = b.updated_at ? new Date(b.updated_at.seconds * 1000) : 0
				if (
					a.status === "IN_DEVELOPMENT" &&
					b.status !== "IN_DEVELOPMENT"
				) {
					result = -1
				} else if (
                    a.status !== "IN_DEVELOPMENT" &&
                    b.status === "IN_DEVELOPMENT"
                ) {
                    result = 1
                } else if (
                    a.status === "NEW" &&
                    b.status !== "NEW"
                ) {
                    result = -1
                } else if (
                    a.status === "NEW" &&
                    b.status !== "NEW"
                ) {
                    result = 1
                } else if (
                    a.active && !b.active
                ) {
                    result = -1
                } else if (
                    !a.active && b.active
                ) {
                    result = 1
                }
				return result;
			});
		}
		return sortedProjects;
	}, [projects, nonZeroProjects]);

	return (
		<Element {...props} className={cn}>
			{title && nonZeroProjects && (
				<Label className="grid-title" as="h1" bold>
					{title}
				</Label>
			)}
			{fourZeroFour && (
				<Row key={`project-placeholder-404`} className="projects">
					<JoinNowPlaceholder mB={20} />
				</Row>
			)}
			{nonZeroProjects && !fourZeroFour && (
				<Row key={`projects-nonzero`} className="projects">
					{sortedProjects &&
						sortedProjects.map((project, idx) => {
							return (
								<ProjectGridItem
									key={`pgi-${project.doc_id}`}
									idx={idx}
									project={project}
									canEdit={canEdit}
								/>
							);
						})}
				</Row>
			)}
			{!nonZeroProjects && !fourZeroFour && (
				<Row className="projects">
					<NoProjectsPlaceholder mB={20} />
				</Row>
			)}
		</Element>
	);
};

export default ProjectGrid;
