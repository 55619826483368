/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import ScrollToHashElement from "../../components/ScrollToHashElement";
import MaxWidthContainer from "../../components/MaxWidthContainer";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import Page from "../../components/Page";
import LastCTA from "./LastCTA";
import Pricing from "./Pricing";
import Hero from "./Hero";
import FAQs from "./FAQs";

import "./Home.css"

// Home Page

const HomePage = ({ user }) => {

    // Scroll to (0,0)
	window.scrollTo(0, 0);
    
    return (
        <Page className="HomePage">
            <ScrollToHashElement />
            <PageHeader appName="stuf" user={user} />
            <MaxWidthContainer>
                <Hero />
                <FAQs />
                <Pricing />
                <LastCTA />
            </MaxWidthContainer>
            <Footer />
        </Page>
    );
};

export default HomePage;
