/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { STATUS_OPTIONS, PROFITABLE_OPTIONS, ACTIVITY_OPTIONS } from "./types";
import {
	deleteUserProject,
	getProjectSlug,
	updateUserProject,
} from "../../utils/project";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import UploadAvatarForm from "../UploadAvatarForm";
import useProject from "../../hooks/useProject";
import { toastIt } from "../../utils/alerts";
import { IoClose } from "react-icons/io5";
import "reactjs-popup/dist/index.css";
import Selector from "../Selector";
import Popup from "reactjs-popup";
import Toggle from "react-toggle";
import Element from "../Element";
import Column from "../Column";
import Avatar from "../Avatar";
import Label from "../Label";
import Row from "../Row";

import "./EditProjectForm.css";
import UploadLogoForm from "../UploadLogoForm";

// Edit Project Form

const EditProjectForm = ({ user, userProfile, className, ...props }) => {
	let cn = className ? `EditProjectForm ${className}` : "EditProjectForm";

	// Grab some things from the params
	const navigate = useNavigate();
	const { cmd } = useParams();

	// Loading
	const [loading, setLoading] = useState(false);

	// Get the state
	const location = useLocation();
	const projectId = location && location?.state ? location.state.docId : null;
	const [existingProject, existingProjectLoading] = useProject(projectId);
	const [originalProject, setOriginalProject] = useState({});
	const [changedProject, setChangedProject] = useState({});
	const [uploadingImage, setUploadingImage] = useState(false);

	const [url, setUrl] = useState("");
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [note, setNote] = useState("");
	const [active, setActive] = useState(true);
	const [profitable, setProfitable] = useState(false);
	const [status, setStatus] = useState(null);
	const [isPublicMrr, setIsPublicMrr] = useState(true);
	const [mrr, setMrr] = useState(0);

	// If the existing project changes then update the state
	useEffect(() => {
		if (existingProject) {
			setUrl(existingProject.url);
			setTitle(existingProject.title);
			setDescription(existingProject.description);
			setNote(existingProject.note);
			setStatus(existingProject.status);
			setIsPublicMrr(existingProject.is_public_mrr);
			setMrr(existingProject.mrr);
			setActive(existingProject.active ? "ACTIVE" : "INACTIVE")
			setOriginalProject(existingProject);
		}
	}, [existingProject]);

	// Create the slug
	const projectSlug = useMemo(() => {
		return getProjectSlug(title);
	}, [title]);

	// Updates
	const updates = useMemo(() => {
		return {
			user_uid: user?.uid,
			url,
			title,
			description,
			note,
			status,
			is_public_mrr: isPublicMrr,
			mrr,
			active: active === "ACTIVE"
		};
	}, [url, title, description, note, isPublicMrr, mrr, status, user, active]);

	// Handlers

	const handleOnClose = () => {
		document.body.style.overflow = "auto";
		navigate(
			location.pathname
				.replace("/add/project", "")
				.replace("/edit/project", "")
		);
	};

	const handleOnUpdate = async () => {
		setLoading(true);
		await updateUserProject(user?.uid, projectSlug, updates);
		const msg = cmd === "edit" ? "Project updated" : "Project added";
		toastIt(msg);
		setLoading(false);
		handleOnClose();
	};

	const handleOnDelete = async () => {
		setLoading(true);
		await deleteUserProject(user?.uid, projectSlug);
		const msg = "Project deleted";
		toastIt(msg);
		setLoading(false);
		handleOnClose();
	};

	// UI vars
	const { pageTitle, submitButtonTitle } = useMemo(() => {
		const pageTitle = cmd === "edit" ? "Edit project" : "Add project";
		const submitButtonTitle =
			cmd === "edit" ? "Save & Close" : "Create & Close";
		return { pageTitle, submitButtonTitle };
	}, [cmd]);

	const canSave = useMemo(() => {
		let canSave = true;
		if (cmd !== "edit") {
			if (!title || title.length < 2) canSave = false;
		} else {
			canSave = false;
			if (updates && originalProject) {
				if (updates.is_public_mrr !== originalProject.is_public_mrr)
					canSave = true;
				if (updates.mrr !== originalProject.mrr) canSave = true;
				if (updates.description !== originalProject.description) canSave = true;
				if (updates.note !== originalProject.note) canSave = true;
				if (updates.status !== originalProject.status) canSave = true;
				if (updates.title !== originalProject.title) canSave = true;
				if (updates.url !== originalProject.url) canSave = true;
				if (updates.active !== originalProject.active) canSave = true;
			}
		}
		return canSave;
	}, [url, title, description, updates, originalProject]);

	// Prevent handler
	const preventHandler = (e) => {
		e.preventDefault();
	};

	const handleOnSelectStatus = (option) => {
		if (status === option) {
			setStatus(null)
		} else {
			setStatus(option)
		}
	}

	const handleInputOnClick = (disabled, prompt) => {
		if (disabled) {
			toastIt(prompt, "warn")
		}
	}

	return (
		<Popup
			open={true}
			className="EditProjectFormPopup"
			onClose={handleOnClose}
			closeOnDocumentClick
			closeOnEscape
			lockScroll
		>
			<button className="close" onClick={handleOnClose}>
				<IoClose size={26} />
			</button>
			<form className={cn} {...props} onSubmit={preventHandler}>
				<Column className="container">
					<Label className="title" as="h1" mT={0} mB={4}>
						{pageTitle}
					</Label>
					<Avatar size={70} mT={6} mB={6} src={existingProject?.photoURL} forceShowLoading={uploadingImage} />
					<UploadLogoForm user={user} project={existingProject} onLoading={(loading) => setUploadingImage(loading)} />
					<Element className="container">
						<Label as="h3" className="subtitle">
							Details
						</Label>
						<input
							type="text"
							className={`textbox textbox-title ${cmd === "edit" ? "disabled" : "enabled"}`}
							value={title}
							maxLength="50"
							onChange={(e) => { if (cmd !== "edit") setTitle(e.target.value)}}
							placeholder="Title*"
							onClick={() => handleInputOnClick(cmd === "edit", "You cannot edit a project's title")}
						/>
						<input
							type="text"
							className="textbox"
							value={url}
							maxLength="50"
							onChange={(e) => setUrl(e.target.value)}
							placeholder="URL"
						/>
						<textarea
							className="textarea"
							value={description}
							maxLength="280"
							onChange={(e) => setDescription(e.target.value)}
							placeholder="Describe the project in 280 chars or less"
						/>
						<input
							type="text"
							className="textbox"
							value={note}
							maxLength="30"
							onChange={(e) => setNote(e.target.value)}
							placeholder="Add a short note"
						/>
						<Label as="h3" className="subtitle">
							Is this project active?
						</Label>
						<Selector
							options={ACTIVITY_OPTIONS}
							mB={6}
							selected={active}
							handleOnSelect={(option) => setActive(option)}
						/>
						<Label as="h3" className="subtitle">
							Is this project profitable?
						</Label>
						<Selector
							options={PROFITABLE_OPTIONS}
							mB={6}
							selected={profitable}
							handleOnSelect={(option) => setProfitable(option)}
						/>
						<Label as="h3" className="subtitle">
							Tags
						</Label>
						<Selector
							options={STATUS_OPTIONS}
							mB={6}
							selected={status}
							handleOnSelect={handleOnSelectStatus}
						/>
						{existingProject && (
							<>
								<Label as="h3" className="subtitle">
									Danger Zone
								</Label>
								<button
									className="delete"
									onClick={handleOnDelete}
								>
									<Label>Delete Project</Label>
								</button>
							</>
						)}
						{/* <Column className="financials">
                        <Label as="h3" className="title">
                            Financials
                        </Label>
                        <Row
                            className="mrr"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Label for="public" as="h3" className="subtitle">
                                Show publicly
                            </Label>
                            <Toggle
                                id="public"
                                icons={false}
                                defaultChecked={isPublicMrr}
                                onChange={(e) => setIsPublicMrr(!isPublicMrr)}
                            />
                        </Row>
                        <Row
                            className="mrr"
                            alignItems="center"
                            justifyContent="space-between"
                            mT={4}
                        >
                            <Label for="MRR" as="h3" className="subtitle">
                                Monthly recur. revenue
                            </Label>
                            <input
                                id="MRR"
                                type="number"
                                className="textbox"
                                value={mrr}
                                min="0.01"
                                step="0.01"
                                max="100000"
                                onChange={(e) => setMrr(e.target.value)}
                                placeholder="$0.00"
                            />
                        </Row>
                    </Column> */}
					</Element>
				</Column>
			</form>
			<Row className="buttons">
				<button
					disabled={!canSave}
					className="submit"
					onClick={handleOnUpdate}
				>
					<Label>{submitButtonTitle}</Label>
				</button>
			</Row>
		</Popup>
	);
};

export default EditProjectForm;
