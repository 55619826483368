/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { NavLink, useLocation } from "react-router-dom";
import React, { useMemo } from "react";
import Row from "../Row";
import "./MainLinks.css";

// Main Links

const MainLinks = ({ className, user, userProfile }) => {
	let cn = className ? `MainLinks ${className}` : "MainLinks";

	const { pathname } = useLocation();
	const slicedPath = pathname.split("/").slice(1)[0];

	// Is the person who's logged in viewing themself?
	const isSelf = useMemo(() => {
		const isSelf = userProfile && userProfile.username === user?.displayName;
		return isSelf;
	}, [userProfile, user]);

	if (slicedPath === "" || ["upgrade"].includes(slicedPath)) return null;

	return (
		<Row className={cn}>
			{user && userProfile && !isSelf && (
				<NavLink to={`/${userProfile?.username}`} className="join">
					Go to me
				</NavLink>
			)}
			{!user && (
				<NavLink to="/" className="join">
					Built with Stuf.dev
				</NavLink>
			)}
		</Row>
	);
};

export default MainLinks;
