/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../clients/firebase";

// Update User Profile

export const updateUserProfile = async (userUid, updates) => {
	const userRef = doc(db, "users", userUid);
	return await setDoc(
		userRef,
		{ ...updates, updated_at: serverTimestamp() },
		{ merge: true }
	);
};
