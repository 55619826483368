/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { onSnapshot } from "firebase/firestore";
import {
    IoClose,
    IoArrowBackOutline,
    IoArrowForwardOutline,
    IoCheckmarkCircleOutline,
    IoCloseCircleOutline,
} from "react-icons/io5";
import {
    auth,
    registerWithEmailAndPassword,
    signUpWithGoogle,
} from "../../clients/firebase";
import useIsUsernameAvailable from "../../hooks/useIsUsernameAvailable";
import {
    FIREBASE_EMAIL_ALREADY_IN_USE
} from "../../utils/errors";
import {
    Link,
    useNavigate,
    useLocation,
    useSearchParams,
} from "react-router-dom";
import { createCustomerCheckoutSession } from "../../utils/stripe";
import React, { useMemo, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import GoogleGIcon from "../GoogleGIcon";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import { useEffect } from "react";
import Element from "../Element";
import Spinner from "../Spinner";
import Loading from "../Loading";
import Label from "../Label";
import Row from "../Row";

import "./SignUpForm.css";

// Sign Up Form

const SignUpForm = ({ className, ...props }) => {
    let cn = className ? `SignUpForm ${className}` : "SignUpForm";

    // Get query params
    const [search, setSearch] = useSearchParams();

    // Steps
    const [step, setStep] = useState(1);
    const [registerError, setRegisterError] = useState(null);
    const usernameRef = useRef();
    const emailRef = useRef();

    // Registering
    const [registering, setRegistering] = useState(false)

    // Username
    const [username, setUsername] = useState("");

    // Profile
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    // Username
    const [availUsername, availUsernameLoading, availUsernameError] =
        useIsUsernameAvailable(username);

    // Checkout session
    const [customerCheckoutSession, setCustomerCheckoutSession] =
        useState(null);
    const [successUrl, setSuccessUrl] = useState(null);
    const [cancelUrl, setCancelUrl] = useState(null);

    const validUsername = useMemo(() => {
        let valid = false;
        valid = username && username.length > 1 && availUsername;
        return valid;
    }, [availUsername, username]);

    // User
    const [user, loading, error] = useAuthState(auth);

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const register = async () => {
        setRegistering(true)
        setRegisterError(null);
        if (!email) {
            setRegisterError("Please enter a valid email.");
            return;
        }
        if (!password) {
            setRegisterError("Please enter a valid password.");
            return;
        }
        const res = await registerWithEmailAndPassword(
            username,
            email,
            password,
            false
        );

        // Was there an error? If not, create the checkout session.
        if (res?.message === FIREBASE_EMAIL_ALREADY_IN_USE) {
            setRegisterError("The email provided is already in use.");
            setRegistering(false)
        } else {
            // Use the user to create a checkout session
            const product = search?.get("product");
            const duration = search?.get("duration");
            const splitOrigin = window.location.origin.split("/")
            const preUsername = splitOrigin.slice(0,3).join("/")
            const userUrl = `${preUsername}/${username}`
            if (res && product && duration) {
                const session = await createCustomerCheckoutSession(
                    res.uid,
                    product,
                    duration,
                    userUrl,
                    userUrl
                    
                );
                setRegistering(false)
                setCustomerCheckoutSession(session);
            } else {
                setRegistering(false)
                navigate(`/${username}`)
            }
        }
    };

    useEffect(() => {
        if (customerCheckoutSession) {
            // Wait for the CheckoutSession to get attached by the extension
            onSnapshot(customerCheckoutSession, (snap) => {
                const { error, url } = snap.data();
                if (error) {
                    // Show an error to your customer and
                    // inspect your Cloud Function logs in the Firebase console.
                    alert(`An error occured: ${error.message}`);
                }
                if (url) {
                    // We have a Stripe Checkout URL, let's redirect.
                    window.location.assign(url);
                }
            });
        }
    }, [customerCheckoutSession]);

    // Hide/show back button
    const backButtonCn = useMemo(() => {
        const backButtonCn = step > 1 ? "back show" : "back";
        return backButtonCn;
    }, [step]);

    // Back button title
    const backButtonTitle = useMemo(() => {
        const backButtonTitle = step > 1 ? "Back" : "Join";
        return backButtonTitle;
    }, [step]);

    // Enabled/disabled next button
    const nextButtonDisabled = useMemo(() => {
        const nextButtonDisabled = !validUsername;
        return nextButtonDisabled;
    }, [validUsername]);

    useEffect(() => {
        if (step === 1) {
            usernameRef?.current?.focus();
        } else {
            emailRef?.current?.focus();
        }
    }, [step, usernameRef, emailRef]);

    // Handlers
    const handleOnClose = () => {
        let forwardingPath = pathname.split("join").slice(0, -1).join("/");
        navigate(forwardingPath);
    };

    const handleOnNext = () => {
        setStep(step + 1);
    };

    const handleOnPrevious = () => {
        setStep(step - 1);
    };

    const handleSetUsername = (value) => {
        const re = /^[a-zA-Z0-9_\b]+$/; // or /^\w+$/ as mentioned
        if ((re.test(value) || value === "") && value?.length < 16) {
            setUsername(value);
        }
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <Popup
            open={true}
            className="SignUpFormPopup"
            onClose={handleOnClose}
            closeOnDocumentClick
            closeOnEscape
        >
            <button className="close" onClick={handleOnClose}>
                <IoClose size={26} />
            </button>
            <form className={cn} {...props} onSubmit={handleOnSubmit}>
                <Row alignItems="center" mB={4} className="title-container">
                    <Element className={backButtonCn}>
                        <IoArrowBackOutline
                            onClick={handleOnPrevious}
                            size={40}
                        />
                    </Element>
                    <Label className="title" as="h1" mT={0}>
                        {backButtonTitle}
                    </Label>
                </Row>
                {step === 1 && (
                    <>
                        <Element className="container">
                            <Element className="inputs">
                                <Label
                                    className="subtitle"
                                    as="h2"
                                    mT={0}
                                    mB={4}
                                >
                                    First, claim your unique link
                                </Label>
                                <input
                                    ref={usernameRef}
                                    type="text"
                                    className="unique-link"
                                    value={username}
                                    onChange={(e) =>
                                        handleSetUsername(e.target.value)
                                    }
                                    placeholder="stevejobs"
                                />
                                <Label className="prompt" as="p">
                                    stuff.dev/
                                </Label>
                                <Label className="input-note" as="h4">
                                    There's still good ones available. And don't
                                    worry, you can always change it later!
                                </Label>
                                {availUsernameLoading && <Spinner size={20} />}
                                {!availUsernameLoading &&
                                    username?.length > 1 &&
                                    validUsername && (
                                        <IoCheckmarkCircleOutline
                                            className="valid"
                                            size={24}
                                        />
                                    )}
                                {!availUsernameLoading &&
                                    username?.length > 1 &&
                                    !validUsername && (
                                        <IoCloseCircleOutline
                                            className="invalid"
                                            size={20}
                                        />
                                    )}
                            </Element>
                            <button
                                className="next"
                                onClick={handleOnNext}
                                disabled={nextButtonDisabled}
                            >
                                Next
                                <IoArrowForwardOutline
                                    onClick={handleOnPrevious}
                                    size={20}
                                />
                            </button>
                            <Label className="join">
                                Already a member?{" "}
                                <Link
                                    className="link-primary"
                                    to={`${pathname.replaceAll(
                                        "join",
                                        "login"
                                    )}`}
                                >
                                    Log in
                                </Link>{" "}
                                now.
                            </Label>
                        </Element>
                    </>
                )}
                {step === 2 && (
                    <>
                        <Element className="container">
                            <Element className="inputs">
                                <Label
                                    className="subtitle"
                                    as="h2"
                                    mT={0}
                                    mB={4}
                                >
                                    Now, create an account
                                </Label>
                                <input
                                    ref={emailRef}
                                    type="text"
                                    className="textbox"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setRegisterError(null);
                                    }}
                                    placeholder="Your email"
                                />
                                <input
                                    type="password"
                                    className="textbox"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    placeholder="Password"
                                />
                            </Element>
                            <Label className="form-error">
                                {registerError}
                            </Label>
                            <button className="join" onClick={register} disabled={registering} style={{ marginBottom: "var(--spacing-4x)"}}>
                                {!registering ? <>Join</> : <Loading color={"var(--green-color)"} />}
                            </button>
                            {/* <button
                                className="google"
                                onClick={(e) => signUpWithGoogle(username)}
                                disabled={registering}
                            >
                                <GoogleGIcon height={20} width={20} />
                                <Label>Join with Google</Label>
                            </button> */}
                            <Label className="join">
                                Already a member?{" "}
                                <Link
                                    className="link-primary"
                                    to={`${pathname.replaceAll(
                                        "join",
                                        "login"
                                    )}`}
                                >
                                    Log in
                                </Link>{" "}
                                now.
                            </Label>
                        </Element>
                    </>
                )}
            </form>
        </Popup>
    );
};

export default SignUpForm;
