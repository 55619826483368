/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import ClipLoader from "react-spinners/ClipLoader";
import React from "react"
import Row from "../Row"
import "./Loading.css"

// Loading

const Loading = ({ color, size=24, ...props }) => {
    return (
        <Row className="Loading" alignItems="center" justifyContent="center" {...props}>
            <ClipLoader
              size={size}
              color={color}
            />
        </Row>
    )
}

export default Loading;