/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import Element from "../Element";
import React from "react";
import "./Column.css";

// Column

const Column = ({
    children,
    className,
    justifyContent,
    alignItems,
    style,
    ...props
}) => {
    let cn = className ? `Column ${className}` : "Column";

    // Add styles
    let styles = {};
    if (justifyContent) {
        styles["justifyContent"] = justifyContent;
    }
    if (alignItems) {
        styles["alignItems"] = alignItems;
    }

    return (
        <Element {...props} className={cn} style={{ ...style, ...styles }}>
            {children}
        </Element>
    );
};

export default Column;
