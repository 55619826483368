/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { query, collection, where, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../clients/firebase";

// useUserSubscription hook

const useUserSubscription = (userUID) => {
   const [subscription, setSubscription] = useState(null);
   const [subscriptionLoading, setSubscriptionLoading] = useState(false);

   useEffect(() => {
      const fetchSubscriptionWithUserUID = async (userUID) => {
         setSubscriptionLoading(true);
         // Get a project
         const q = query(
               collection(db, "stripe_customers", userUID, "subscriptions"),
               where("status", "in", ["trialing", "active"])
         );
         const querySnapshot = await getDocs(q);

         // Iterate the query results and determine if we have a subscription, setting  it if we do.
         querySnapshot.forEach((doc) => {
               if (doc.data()) {
                  setSubscription(doc.data().role);
               }
         });
         setSubscriptionLoading(false);
      };

      // Fetch the project if we have an user UID
      if (userUID) {
         fetchSubscriptionWithUserUID(userUID);
      }
   }, [userUID]);

   return [subscription, subscriptionLoading];
};

export default useUserSubscription;
