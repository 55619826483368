/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { useCollection } from "react-firebase-hooks/firestore"
import { query, collection, where } from "firebase/firestore"
import { useEffect, useMemo, useState } from "react"
import { db } from "../clients/firebase"

// useUserProjects hook

const useUserProjects = (userUID) => {

    // Construct our projects query
    const [userProjectsQuery, setUserProjectsQuery] = useState(null)
    const [userProjects, setUserProjects] = useState([]);
    const [userProjectsSnapshots, userProjectsLoading, userProjectsError] = useCollection(userProjectsQuery);

    useEffect(() => {
        if (userProjectsSnapshots) {
            let newUserProjects = []
            userProjectsSnapshots.docs.forEach((doc) => {
               newUserProjects.push({ doc_id: doc.id, ...doc.data() })
            })
            setUserProjects(newUserProjects)
        }
    }, [userProjectsSnapshots])

    // Create the query to get the user's projects
    useEffect(() => {
        if (userUID) {
            const aQuery = query(collection(db, "projects"), where("user_uid", "==", userUID))
            if (aQuery) {
                setUserProjectsQuery(aQuery)
            }
        }
    }, [userUID])

    const loading = useMemo(() => {
        return typeof userProjectsSnapshots === "undefined" || userProjectsLoading ? true : userProjectsLoading
    }, [userProjectsLoading, userProjectsSnapshots])

    return [userProjects, loading, userProjectsError]
}

export default useUserProjects;