/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { useCollectionData } from "react-firebase-hooks/firestore"
import { query, collection, where } from "firebase/firestore"
import { useEffect, useMemo, useState } from "react"
import { db } from "../clients/firebase"

// useUserProfile hook

const useUserProfile = (lookup) => {

    // Create state for our "users" (user profile) query and userProfile
    const [userProfileQuery, setUserProfileQuery] = useState(null)
    const [userProfile, userProfileLoading, userProfileError] = useCollectionData(userProfileQuery)

    const { memoizedLookup, lookupField } = useMemo(() => {
        const lookupField = typeof lookup === "string" ? "username" : "uid"
        const memoizedLookup = typeof lookup === "string" ? lookup : lookup?.uid
        return { memoizedLookup, lookupField }
    }, [lookup])

    // Construct our "users" query
    useEffect(() => {
        if (memoizedLookup && lookupField) {
            const aQuery = query(collection(db, "users"), where(lookupField, "==", memoizedLookup))
            setUserProfileQuery(aQuery)
        }
    }, [memoizedLookup, lookupField])

    const res = userProfile && userProfile.length > 0 ? userProfile[0] : null
    const loading = !userProfile || userProfileLoading

    return [res, loading, userProfileError]

}

export default useUserProfile;