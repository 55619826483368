/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { addDoc, collection } from "firebase/firestore";
import { db } from "../clients/firebase"

// Vars

const PRICE_ID_BASIC_MONTH = process.env.REACT_APP_PRICE_ID_BASIC_MONTH
const PRICE_ID_BASIC_YEAR = process.env.REACT_APP_PRICE_ID_BASIC_YEAR
const PRICE_ID_GROWTH_MONTH = process.env.REACT_APP_PRICE_ID_GROWTH_MONTH
const PRICE_ID_GROWTH_YEAR = process.env.REACT_APP_PRICE_ID_GROWTH_YEAR
const PRICE_ID_LIFETIME_PASS = process.env.REACT_APP_PRICE_ID_LIFETIME_PASS

// Utils

const createCustomerCheckoutSession = async (userUid, product, duration, successUrl, cancelUrl) => {

   let priceId = null
   let productString = `${product}-${duration}`
   switch (productString) {
      case "basic-month":
         priceId = PRICE_ID_BASIC_MONTH
         break;
      case "basic-year":
         priceId = PRICE_ID_BASIC_YEAR
         break;
      case "growth-month":
         priceId = PRICE_ID_GROWTH_MONTH
         break;
      case "growth-year":
         priceId = PRICE_ID_GROWTH_YEAR
         break;
      case "lifetime_pass-forever":
         priceId = PRICE_ID_LIFETIME_PASS
         break;
      default:
         priceId = PRICE_ID_BASIC_YEAR
         break;
   }
   
   const checkoutSessionRef = await addDoc(
      collection(db, "stripe_customers", userUid, "checkout_sessions"),
      {
         price: priceId,
         success_url: successUrl,
         cancel_url: cancelUrl,
         mode: "payment"
      }
   );

   return checkoutSessionRef
}

export { createCustomerCheckoutSession };