/*
 * Copyright (C) 2022 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify"

// Error handlers

export const handleError = (err) => {
    console.log(err.message)
    let msg = 'Hmm. Something went wrong.'
    switch (err.message) {
        case FIREBASE_USER_NOT_FOUND:
        case FIREBASE_INVALID_EMAIL: {
            msg = 'Invalid credentials. Please try again.'
            break;
        }
        case ADD_PRODUCT_URL_FAIL: {
            msg = 'Add product failed. Please try again.'
            break;
        }
        case LOCAL_INVALID_EMAIL: {
            msg = 'Please enter a valid email.'
            break;
        }
        case LOCAL_INVALID_PASSWORD: {
            msg = 'Please enter a valid password.'
            break;
        }
        default:
            break;
            
    }
    toast.error(msg)
}

// Errors

export const USER_DOES_NOT_EXIST = 'Local: Error (auth/user-not-found).'
export const FIREBASE_USER_NOT_FOUND = 'Firebase: Error (auth/user-not-found).'
export const FIREBASE_INVALID_EMAIL = 'Firebase: Error (auth/invalid-email).'
export const FIREBASE_EMAIL_ALREADY_IN_USE = 'Firebase: Error (auth/email-already-in-use).'
export const ADD_PRODUCT_URL_FAIL = 'add_product_url_fail'
export const LOCAL_INVALID_EMAIL = 'Local: Error (auth/email-not-provided)'
export const LOCAL_INVALID_PASSWORD = 'Local: Error (auth/password-not-provided)'