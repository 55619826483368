/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import MaxWidthContainer from "../../components/MaxWidthContainer";
import Page from "../../components/Page";
import "./NotFound.css";

// Not Found Page

const NotFoundPage = () => {
  return (
    <Page className="NotFoundPage">
      <MaxWidthContainer>
      </MaxWidthContainer>
    </Page>
  );
};

export default NotFoundPage;
