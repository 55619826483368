/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { NavLink, useNavigate } from "react-router-dom";
import React, { useRef, useEffect } from "react";

import MaxWidthContainer from "../MaxWidthContainer";
import { BUTTON_STYLE_CLASS_DARK } from "../Button";
import ActionButton from "../ActionButton";
import DropdownMenu from "./DropdownMenu";
import Row from "../Row";


import "./PageHeader.css";

// PageHeader

const PageHeader = ({ appName, user }) => {
	const headerRef = useRef();

	const navigate = useNavigate();

	const handleOnSignUpClick = () => {
		navigate("/upgrade");
	};

	const handleOnLoginClick = () => {
		navigate("/login");
	};

	const handleOnProfileClick = () => {
		navigate(`/${user?.displayName}`);
	};

	const handleStyleOnScroll = () => {
		if (!headerRef.current) return;
		const scrollTop = document.documentElement.scrollTop;
		if (scrollTop > 60) {
			headerRef.current.classList.add("border-bottom");
		} else {
			headerRef.current.classList.remove("border-bottom");
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", handleStyleOnScroll);
		return () => {
			window.removeEventListener("scroll", handleStyleOnScroll);
		};
	}, []);

	console.log("TESTING user", user)

	const MobileContent = () => {
		return (
			<Row className="mobile-content-container">
				<Row className="left">
					<NavLink className="titles row" to="/">
						<h1 className="title">{appName}</h1>
					</NavLink>
				</Row>
				<Row className="middle"></Row>
				<Row className="right">
					{!user && <ActionButton
						title={"Log in"}
						style={BUTTON_STYLE_CLASS_DARK}
						onClick={handleOnLoginClick}
					/>}
					{user && <ActionButton
						title={"My page"}
						style={BUTTON_STYLE_CLASS_DARK}
						onClick={handleOnProfileClick}
					/>}
				</Row>
			</Row>
		);
	};

	const DesktopContent = () => {
		return (
			<Row className="desktop-content-container">
				<Row className="left">
					<NavLink className="titles row" to="/">
						<h1 className="title">{appName}</h1>
					</NavLink>
				</Row>
				<Row className="middle"></Row>
				<Row className="right">
					{!user && <ActionButton
						title={"Log in"}
						style={BUTTON_STYLE_CLASS_DARK}
						onClick={handleOnLoginClick}
					/>}
					{user && <ActionButton
						title={"My page"}
						style={BUTTON_STYLE_CLASS_DARK}
						onClick={handleOnProfileClick}
					/>}
				</Row>
			</Row>
		);
	};

	return (
		<div ref={headerRef} className="PageHeader">
			<MaxWidthContainer className="container">
				<MobileContent />
				<DesktopContent />
			</MaxWidthContainer>
		</div>
	);
};

export default PageHeader;
