/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import {
    auth,
    logInWithEmailAndPassword,
    logInWithGoogle,
} from "../../clients/firebase";
import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { IoClose } from "react-icons/io5";
import GoogleGIcon from "../GoogleGIcon";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import Element from "../Element";
import Label from "../Label";
import "./LoginForm.css";

// Login Form

const LoginForm = ({ className, ...props }) => {
    let cn = className ? `LoginForm ${className}` : "LoginForm";

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, loading, error] = useAuthState(auth);

    const navigate = useNavigate();
    const { pathname } = useLocation();

    // Enabled/disabled next button
    const loginButtonDisabled = useMemo(() => {
        const invalidEmail =  !email || email.length < 3
        const invalidPassword = !password || password.length < 3
        const loginButtonDisabled = invalidEmail || invalidPassword;
        return loginButtonDisabled;
    }, [email, password]);

    useEffect(() => {
        if (loading) {
            // Trigger loading screen
            return;
        }
        if (user) navigate(`/${user?.displayName}`);
    }, [user, loading, navigate]);

    const handleOnClose = () => {
        document.body.style.overflow = "auto";
        let forwardingPath = pathname && pathname.endsWith('/') ? pathname.slice(0, -1) : pathname
        forwardingPath = forwardingPath.replace("login", "")
        navigate(forwardingPath)
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <Popup
            open={true}
            className="LoginFormPopup"
            onClose={handleOnClose}
            closeOnDocumentClick
            closeOnEscape
        >
            <button className="close" onClick={handleOnClose}>
                <IoClose size={26} />
            </button>
            <form className={cn} {...props} onSubmit={handleOnSubmit}>
                <Label className="title" as="h1" mT={0} mB={4}>
                    Log in
                </Label>
                <Element className="container">
                    <input
                        type="text"
                        className="textbox"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                    />
                    <input
                        type="password"
                        className="textbox"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                    />
                    <button
                        disabled={loginButtonDisabled}
                        className="login"
                        onClick={() =>
                            logInWithEmailAndPassword(email, password)
                        }
                    >
                        Log in
                    </button>
                    <Element className="divider">
                        <Label>or</Label>
                    </Element>
                    <button className="google" onClick={logInWithGoogle}>
                        <GoogleGIcon height={20} width={20} />
                        <Label>Log in with Google</Label>
                    </button>
                    <Label className="join">
                        Not a member?{" "}
                        <Link className="link-primary" to={`${pathname.replaceAll("login", "join")}`}>
                            Join
                        </Link>{" "}
                        now.
                    </Label>
                </Element>
            </form>
        </Popup>
    );
};

export default LoginForm;
