/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import Element from "../../../components/Element";
import Column from "../../../components/Column";
import Label from "../../../components/Label";
import Collapsible from "react-collapsible";
import React, { useState } from "react";
import Trigger from "./Trigger";

import "./FAQs.css";

// FAQs

const FAQs = ({ className, ...props }) => {
	let cn = className ? `FAQs ${className}` : "FAQs";

	// Questions
	const [openQuestions, setOpenQuestions] = useState({ 1: false, 2: false });

	const handleOnClick = (index) => {
		const newState = !openQuestions[index];
		const newQuestions = { ...openQuestions, [index]: newState };
		setOpenQuestions(newQuestions);
	};

	return (
		<Column {...props} className={cn} as="section" id="hero-faqs">
			<Label as="h2" className="title" mT={12} mB={8}>
				Frequently asked questions
			</Label>
			<Element
				className="collapse-wrapper"
				onClick={() => handleOnClick("1")}
			>
				<Collapsible
					open={openQuestions["1"] === true}
					transitionTime={75}
					transitionCloseTime={75}
					trigger={<Trigger title="Can anyone sign up?" />}
					className="question"
					openedClassName="question open"
					contentOuterClassName="outer-content"
					contentInnerClassName="inner-content"
				>
					<Label>
						Absolutely! Stuf is designed for developers of all
						levels, from beginners to seasoned professionals.
						Whether you're working on personal projects, startups,
						or anything in-between, the platform is the perfect
						place to showcase your work. All you need is a passion
						for development and a project to share.
					</Label>
				</Collapsible>
			</Element>
			<Element
				className="collapse-wrapper"
				onClick={() => handleOnClick("2")}
			>
				<Collapsible
					open={openQuestions["2"] === true}
					transitionTime={75}
					transitionCloseTime={75}
					trigger={<Trigger title="Does it cost anything?" />}
					className="question"
					openedClassName="question open"
					contentOuterClassName="outer-content"
					contentInnerClassName="inner-content"
				>
					<Label>
						The basic plan is completely free, offering essential
						features suitable for most users. I believe in
						accessible technology, so I ensure that everyone can
						showcase their projects without a barrier. For users
						seeking advanced features, Stuf offers a premium
						subscription plan with additional capabilities to
						enhance your experience.
					</Label>
				</Collapsible>
			</Element>
			<Element
				className="collapse-wrapper"
				onClick={() => handleOnClick("3")}
			>
				<Collapsible
					open={openQuestions["3"] === true}
					transitionTime={75}
					transitionCloseTime={75}
					trigger={<Trigger title="How do I get started?" />}
					className="question"
					openedClassName="question open"
					contentOuterClassName="outer-content"
					contentInnerClassName="inner-content"
				>
					<Label>
						Getting started with Stuf is easy! Simply sign up with
						your email, create your profile, and you're ready to go.
						Once your account is set up, you can begin adding your
						projects. The user-friendly interface allows you to
						effortlessly upload project details, images, and links,
						making it a breeze to showcase your work to the world.
					</Label>
				</Collapsible>
			</Element>
		</Column>
	);
};

export default FAQs;
