/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { FREE_PRODUCT, INDIE_PRODUCT } from "./types";
import React, { useState, useEffect } from "react";
import { auth } from "../../clients/firebase";
import { IoClose } from "react-icons/io5";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import Element from "../Element";
import Product from "./Product";
import Column from "../Column";
import Label from "../Label";

import "./UpgradeForm.css";

// Upgrade Form

const UpgradeForm = ({ className, ...props }) => {
    let cn = className ? `UpgradeForm ${className}` : "UpgradeForm";

    const { pathname } = useLocation();

    // User
    const [user, loading,] = useAuthState(auth);

    // Billing
    const [billingInterval,] = useState("month");

    const navigate = useNavigate();

    // Handlers
    const handleOnClose = () => {
        let forwardingPath = pathname && pathname.endsWith('/') ? pathname.slice(0, -1) : pathname
        forwardingPath = forwardingPath.replace("/upgrade", "/")
        navigate(forwardingPath)
    };

    useEffect(() => {
        if (loading) {
            // Trigger loading screen
            return;
        }
        if (user) navigate(`/${user?.displayName}`);
    }, [user, loading, navigate]);

    return (
        <Popup
            open={true}
            className="UpgradeFormPopup"
            onClose={handleOnClose}
            closeOnDocumentClick
            closeOnEscape
        >
            <button className="close" onClick={handleOnClose}>
                <IoClose size={26} />
            </button>
            <Element className={cn} {...props}>
                <Column
                    alignItems="center"
                    justifyContent="center"
                    mB={4}
                    className="title-container"
                >
                    <Label className="title" as="h1" mT={0}>
                        Choose a plan
                    </Label>
                    <Label className="subtitle" as="h2" mB={8}>
                        Select the plan that works best for you. You can change
                        whenever.
                    </Label>
                    {/* <Row className="billing-interval-container" justifyContent="flex-start">
                        <Row className="billing-interval-label-container month">
                            <Label className="billing-interval-label " mR={3}>Month</Label>
                        </Row>
                        <Toggle
                            id="billing-interval-status"
                            icons={false}
                            defaultChecked={billingInterval === "year"}
                            onChange={handleBillingIntervalOnChange}
                        />
                        <Row className="billing-interval-label-container">
                            <Label className="billing-interval-label" mL={3} >Yearly</Label>
                            <Label className="billing-interval-discount" mL={3} >33% OFF</Label>
                        </Row>
                    </Row> */}
                </Column>
                <Element className="products">
                    <Product
                        billingInterval={billingInterval}
                        product={FREE_PRODUCT}
                    />
                    <Product
                        billingInterval={billingInterval}
                        product={INDIE_PRODUCT}
                    />
                </Element>
            </Element>
        </Popup>
    );
};

export default UpgradeForm;
