/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import Label from "../../../components/Label";
import { HiPlusSm } from "react-icons/hi";
import Row from "../../../components/Row";
import React, { useState } from "react";
import "./FAQs.css";
import Element from "../../../components/Element";

// FAQs

const Trigger = ({ title, className, onChange, ...props }) => {
    let cn = className ? `Trigger ${className}` : "Trigger";

    const [triggered, setTriggered] = useState(false);

    const handleOnClick = () => {
        setTriggered(!triggered);
        if (typeof onChange === "function") {
            onChange(triggered);
        }
    };

    return (
        <>
            <Row {...props} className={cn} onClick={handleOnClick}>
                <Label>{title}</Label>
                <Element className="icon-wrapper">
                    <HiPlusSm size={24} />
                </Element>
            </Row>
        </>
    );
};

export default Trigger;
