/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { useLocation, useNavigate } from "react-router-dom";
import { STATUS_OPTIONS } from "../EditProjectForm/types";
import { HiOutlineDotsVertical } from "react-icons/hi";
import React, { useCallback } from "react";
import Element from "../Element";
import Avatar from "../Avatar";
import Column from "../Column";
import Label from "../Label";
import Row from "../Row";
import Tag from "./Tag";

import "./ProjectGridItem.css";

// Project Grid Item

const ProjectGridItem = ({ idx, className, canEdit, project, ...props }) => {
	let cn = className ? `ProjectGridItem ${className}` : "ProjectGridItem";

	const title = project?.title ?? "";
	const icon = project?.icon ?? "";
	const logo = project?.photoURL ?? null;
	const mrr = project?.mrr ?? "0";
	const url = project?.url ?? "";
	const active = project?.active;
	const profitable = project?.profitable ?? false;
	const status = project?.status ?? null;
	const statusOption = status
		? STATUS_OPTIONS.find((option) => option.type === status)
		: null;
	const docId = project?.doc_id ?? null;

	const handleOnClick = useCallback(() => {
		if (url) window.open(url, "_blank");
	}, [url]);

	const { pathname } = useLocation();
	const navigate = useNavigate();

	const handleOptionsOnClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		navigate(`${pathname}/edit/project`, { state: { docId } });
	};

	return (
		<Row {...props} className={cn} onClick={handleOnClick}>
			{/* {status && (
                <>
                    <Element className="badge">{`${statusOption.icon} ${statusOption.title}`}</Element>
                    <Element className="badge-shadow" />
                </>
            )} */}
			<Label className="index">{idx + 1}</Label>
			<Row
				className="content"
				alignItems="center"
				justifyContent="space-between"
			>
				<Column className="top">
					<Row className="header" justifyContent="space-between">
						<Row>
							{logo && <Avatar size={22} src={logo} mR={2} />}
							{!logo && <Element style={{ width: "30px" }} />}
							{icon && (
								<Label as="h2" mR="2">
									{icon}
								</Label>
							)}
							<Label as="h3" className="title">
								{title}
							</Label>
						</Row>
						<Row justifyContent="flex-end">
							{mrr > 0 && (
								<Label as="h6" mR={3}>
									${mrr} MRR
								</Label>
							)}
						</Row>
					</Row>
					{/* <Row className="subheader">
						<Label as="p" className="desc">
							{description}
						</Label>
					</Row> */}
				</Column>
				<Row>
					{statusOption && (
						<Tag
							mR={3}
							title={`${statusOption.icon} ${statusOption.title} `}
						/>
					)}
					{active && <Element className="active" />}
					{profitable && (
						<button className="edit" onClick={handleOptionsOnClick}>
							<HiOutlineDotsVertical size={20} />
						</button>
					)}
					{canEdit && (
						<button className="edit" onClick={handleOptionsOnClick}>
							<HiOutlineDotsVertical size={24} />
						</button>
					)}
				</Row>
			</Row>
			{/* <Row className="bottom" justifyContent="space-between" width="100%">
                <Label as="p" className="factoid">
                    {factIcon} {note}
                </Label>
            </Row> */}
		</Row>
	);
};

export default ProjectGridItem;
