/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import Element from "../Element";
import "./Spinner.css";

// Spinner

const Spinner = ({ size }) => {
    const borderWidth = size ? parseInt(size / 6) : 10;

    return (
        <Element
            className="Spinner"
            style={{
                height: `${size}px`,
                width: `${size}px`,
                borderWidth: `${borderWidth}px`,
            }}
        />
    );
};

export default Spinner;
