/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

// import {
//     getFunctions,
//     connectFunctionsEmulator,
// } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { handleError, USER_DOES_NOT_EXIST } from "../utils/errors";
import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    // connectAuthEmulator,
    updateProfile,
} from "firebase/auth";
import {
    getFirestore,
    // connectFirestoreEmulator,
    query,
    getDocs,
    collection,
    where,
    addDoc,
    setDoc,
    doc
} from "firebase/firestore";

// Config

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize our Firebase app and services

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);
const db = getFirestore(app);
// const functions = getFunctions(app);

// if (window.location.hostname === "localhost") {
//     console.log("Using TEST environment.");
//     connectAuthEmulator(auth, "http://localhost:9099");
//     connectFirestoreEmulator(db, "localhost", 8080);
//     connectFunctionsEmulator(functions, "localhost", 5001);
//     connectStorageEmulator(storage, "localhost", 9199)
// }

//
// AUTH
//

// Log out
const logout = () => {
    signOut(auth);
};

// Sign-in/Register: Google Log In
const googleProvider = new GoogleAuthProvider();
const signUpWithGoogle = async (username, showError=true) => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        await updateProfile(user, { displayName: username });
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
            await addDoc(collection(db, "users"), {
                uid: user.uid,
                username,
                name: user.displayName,
                authProvider: "google"
            });
        }
    } catch (err) {
        if (showError) handleError(err);
    }
};

const logInWithGoogle = async (username, showError=true) => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
            if (showError) handleError({ message: USER_DOES_NOT_EXIST });
        }
    } catch (err) {
        if (showError) handleError(err);
    }
};

// Sign-in: Email and password
const logInWithEmailAndPassword = async (email, password, showError=true) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        if (showError) handleError(err);
    }
};

// Register: Email and password
const registerWithEmailAndPassword = async (username, email, password, showError=true) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        await updateProfile(user, { displayName: username });
        const userRef = doc(db, "users", `${user.uid}`)
        await setDoc(userRef, {
            uid: user.uid,
            username,
            name: username,
            authProvider: "local"
        }, { merge: true });
        return user
    } catch (err) {
        if (showError) {
            handleError(err);
        } else {
            return err
        }
    }
};

// Reset: Email and password
const sendPasswordReset = async (email, showError=true) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
    } catch (err) {
        if (showError) handleError(err);
    }
};

//
// CALLABLES
//

//
// EXPORTS
//

export {
    app,
    auth,
    db,
    storage,
    logInWithGoogle,
    signUpWithGoogle,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    getFunctions,
    httpsCallable
};
