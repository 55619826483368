/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { Outlet } from "react-router-dom";
import PageHeader from "../PageHeader";
import React from "react"
import "./Layout.css"

// Layout

const Layout = ({ className,  }) => {

    let cn = className ? `Layout ${className}` : 'Layout'

    return (
        <div className={cn}>
            {/* <PageHeader /> */}
            <Outlet />
        </div>
    )
}

export default Layout;