/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Types

export const UNCLAIMED_PROFILE = {
   name: '404 Not Found',
};

export const UNCLAIMED_PROJECTS = [
    {
        name: "It's free to join!",
        url: "https://www.stuf.dev/create",
        description: "Click 'Share your Stuf' below to get started creating your page.",
    },
];

export const UNCLAIMED_TITLE = "";
