/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import Element from "../Element"
import Label from "../Label"
import React from "react"

import "./Tag.css"

// Tag

const Tag = ({ className, title, ...props }) => {

    let cn = className ? `Tag ${className}` : 'Tag'

    return (
        <Element className={cn} {...props}>
            <Label>{title}</Label>
        </Element>
    )
}

export default Tag;