/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { query, collection, where, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../clients/firebase";

// useDidUserPay hook

const useDidUserPay = (userUID) => {
   const [didPay, setDidPay] = useState(false);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      const fetchUserPayment = async (userUID) => {
         setLoading(true);
         const q = query(
               collection(db, "stripe_customers", userUID, "payments"),
               where("status", "in", ["succeeded"])
         );
         const querySnapshot = await getDocs(q);
         // Iterate the query results and determine if
         // we have a payment, setting  it if we do.
         querySnapshot.forEach((doc) => {
               if (doc.data()) {
                  setDidPay(true);
               }
         });
         setLoading(false);
      };

      // Fetch the status if we have a UID
      if (userUID) {
         fetchUserPayment(userUID);
      }
   }, [userUID]);

   return [didPay, loading];
};

export default useDidUserPay;
