/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { auth } from "../clients/firebase";

// getCustomClaimRole

const getCustomClaimRole = async () => {
   await auth.currentUser.getIdToken(true);
   const decodedToken = await auth.currentUser.getIdTokenResult();
   return decodedToken.claims.stripeRole;
}

export default getCustomClaimRole;