/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { UNCLAIMED_PROFILE, UNCLAIMED_TITLE } from "../../utils/unclaimed";
import MaxWidthContainer from "../../components/MaxWidthContainer";
import useUserProjects from "../../hooks/useUserProjects";
import useUserProfile from "../../hooks/useUserProfile";
import ProjectGrid from "../../components/ProjectGrid";
import Element from "../../components/Element";
import { useLocation, useParams } from "react-router-dom";
import Page from "../../components/Page";
import { useMemo } from "react";
import Header from "./Header";
import "./Person.css";
import useUserSubscription from "../../hooks/useUserSubscription";
import AuthFooter from "../../components/AuthFooter";
import AddButton from "../../components/AddButton";
import Loading from "../../components/Loading";

// Exclude rendering this component for the following pages

const EXCLUDING_PAGES = ["login", "join", "upgrade"]

// Helpers

const extractPage = (str) => {
	var rx = /\/\/(?:.+\/)(.+\/?)/g;
	var arr = rx.exec(str);
	return arr[1]; 
  }

// Person Page

const PersonPage = ({ user, userProfile }) => {
	// Scroll to (0,0)
	window.scrollTo(0, 0);

	// Get the person slug
	const { username } = useParams();

	// Get the location
	const location = useLocation()

	const page = useMemo(() => {
		return extractPage(window.location.href)
	},[location?.pathname])

	// Is the person who's logged in viewing themself?
	const isSelf = useMemo(() => {
		const isSelf = userProfile && userProfile.username === username;
		return isSelf;
	}, [userProfile, username]);

	// Get the profile & projects for the user whose page we're viewing
	const [pageProfile, pageProfileLoading, pageProfileError] =
		useUserProfile(username);
	const [pageProjects, pageProjectsLoading, pageProjectsError] =
		useUserProjects(pageProfile?.uid);

	// Get the subscription status of the person whose page we're vieweing
	const [subscription] = useUserSubscription(pageProfile?.uid);

	// Get the title for the projects grid
	const { projectsTitle } = useMemo(() => {
		const projectsTitle = pageProfile?.projects_title
			? pageProfile.projects_title
			: `Projects`;
		return { projectsTitle };
	}, [pageProfile]);

	// Are there non-zero projects
	const { nonZeroProjects } = useMemo(() => {
		const nonZeroProjects = pageProjects && pageProjects.length > 0;
		return { nonZeroProjects };
	}, [pageProjects]);

	// Does this page belong to a user?
	const { unclaimedPage } = useMemo(() => {
		const unclaimedPage = !pageProfile && !pageProfileLoading;
		return { unclaimedPage };
	}, [pageProfile, pageProfileLoading]);

	const cn = nonZeroProjects ? "PersonPage" : "PersonPage empty";

	const useableProfile = unclaimedPage ? UNCLAIMED_PROFILE : pageProfile;
	const useableProjects = unclaimedPage ? [] : pageProjects;
	const useableTitle = unclaimedPage ? UNCLAIMED_TITLE : projectsTitle;

	if (EXCLUDING_PAGES.includes(page)) {
		return null
	}

	return (
		<>
			<Page className={cn}>
				{pageProjectsLoading && (
					<Loading color={"white"} size={40} mT={12} />
				)}
				{!pageProjectsLoading && (
					<MaxWidthContainer style={{ minHeight: "100vh" }}>
						<Element className="grid">
							<>
								<Header
									userProfile={useableProfile}
									userSubscription={subscription}
									canEdit={isSelf}
									user={user}
								/>
								<ProjectGrid
									fourZeroFour={unclaimedPage}
									projects={useableProjects}
									title={useableTitle}
									canEdit={isSelf}
								/>
							</>
						</Element>
					</MaxWidthContainer>
				)}
			</Page>
			{!pageProjectsLoading && (
				<AuthFooter user={user} userProfile={userProfile} />
			)}
			{user && isSelf && <AddButton />}
		</>
	);
};

export default PersonPage;
